import React from "react"
// import ReactGA from "react-ga"

interface Props {
  homePage?: boolean
}
class Footer extends React.Component<Props> {
  public handleCookiePolicyClick = () => {
    // ReactGA.event({
    //   category: "COOKIE POLICY",
    //   action: `COOKIE POLICY Clicked`,
    //   label: "COOKIE POLICY",
    // })
  }
  public handlePrivacyPolicyClick = () => {
    // ReactGA.event({
    //   category: "PRIVACY POLICY",
    //   action: `PRIVACY POLICY Clicked`,
    //   label: "PRIVACY POLICY",
    // })
  }
  public handleTermsAndConditionClick = () => {
    // ReactGA.event({
    //   category: "TERMS AND CONDITIONS",
    //   action: `TERMS AND CONDITIONS Clicked`,
    //   label: "TERMS AND CONDITIONS",
    // })
  }
  public render() {
    return (
      <div className="flex text-center justify-center w-full footer bottom-0">
        <footer>
          <h5 style={{ color: "white" }}>
            <a
              className="footer-link"
              href="/terms-conditions"
              onClick={this.handleTermsAndConditionClick}
            >
              {" Terms & Conditions "}
            </a>
            |
            <a
              className="footer-link"
              href="/privacy-policy"
              onClick={this.handlePrivacyPolicyClick}
            >
              {" Privacy Policy "}
            </a>
            |
            <a
              className="footer-link"
              href="/cookie-policy"
              onClick={this.handleCookiePolicyClick}
            >
              {" Cookie Policy "}
            </a>
          </h5>
        </footer>
      </div>
    )
  }
}

export default Footer
