import ReorderIcon from "@material-ui/icons/Reorder"
import React, { useEffect, useState } from "react"

import classes from "./header.module.css"

const navItems = [
  {
    title: "COMPETITION",
    url: "https://www.discoverthesoundofislay.co.uk/",
  },
  {
    title: "OUR STORY",
    url: "https://bunnahabhain.com/our-story",
  },
  {
    title: "OUR WHISKY",
    url: "https://bunnahabhain.com/our-whisky/",
  },
  {
    title: "OUR METHODS",
    url: "https://bunnahabhain.com/our-methods/",
  },
  {
    title: "VISIT US",
    url: "https://bunnahabhain.com/visit-us/",
  },
]
const Header = () => {
  const [showNav, setShowNav] = useState(false)
  const handleClick = () => {
    setShowNav(!showNav)
  }
  const navItemsLen = navItems.length
  const [width, setWidth] = useState(window.innerWidth)

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
  }, [])
  const mobileScreen = width <= 650
  return (
    <div className={[classes.header__items, "sm:bg-white"].join(" ")}>
      <a href="https://bunnahabhain.com">
        <img
          src={mobileScreen ? "/logo-footer.svg" : "/logo-dark.svg"}
          className={classes.header__logo}
        />
      </a>
      <ReorderIcon className={classes.dropdown__nav} onClick={handleClick} />
      <ul
        className={showNav ? classes.header__menu__mbl : classes.header__menu}
      >
        {navItems.map((items, index) => {
          return (
            <li
              key={index}
              className={classes.list__items}
              style={{
                borderBottom: mobileScreen
                  ? navItemsLen === index + 1
                    ? ""
                    : "1px solid lightgray"
                  : "",
              }}
            >
              <a className={classes.nav__link} href={items.url}>
                {items.title}
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Header
