import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core"
import React, { ChangeEvent, FC } from "react"

import classes from "../../../styles/DateOfBirth.module.css"

interface Props {
  error: boolean
  onChange: (
    e: ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
  ) => void
  value: number
}

const YearField: FC<Props> = ({ onChange, error, value }) => {
  const today = new Date()
  const year = today.getFullYear() - 18
  const yearFrom = year - 1899
  return (
    <FormControl className={classes.formControl} error={error}>
      <InputLabel className="text-white" id="year-label">
        Year
      </InputLabel>
      <Select
        labelId="year-label"
        id="year"
        onChange={onChange}
        value={value}
        inputProps={{
          className: classes.selectBox,
        }}
      >
        {Array.from(Array(yearFrom), (e, i) => {
          return (
            <MenuItem key={e} value={1900 + i}>
              {1900 + i}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default YearField
