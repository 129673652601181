import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core"
import React, { ChangeEvent, FC } from "react"

import classes from "../../../styles/DateOfBirth.module.css"

interface Props {
  error: boolean
  onChange: (
    e: ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
  ) => void
  value: number
}
const DateField: FC<Props> = ({ onChange, error, value }) => {
  return (
    <FormControl className={classes.formControl} error={error}>
      <InputLabel className="text-white" id="day-label">
        Day
      </InputLabel>
      <Select
        labelId="day-label"
        id="day"
        inputProps={{
          className: classes.selectBox,
        }}
        value={value}
        onChange={onChange}
      >
        {Array.from(Array(31), (e, i) => {
          return (
            <MenuItem key={e} value={i + 1}>
              {i + 1}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}
export default DateField
