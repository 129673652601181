import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core"
import React, { ChangeEvent, FC } from "react"

import classes from "../../../styles/DateOfBirth.module.css"

interface Props {
  error: boolean
  onChange: (
    e: ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>,
  ) => void
  value: number
}

const MonthField: FC<Props> = ({ onChange, error, value }) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  return (
    <FormControl className={classes.formControl} error={error}>
      <InputLabel className="text-white" id="month-label">
        Month
      </InputLabel>
      <Select
        labelId="month-label"
        id="month"
        onChange={onChange}
        value={value}
        inputProps={{
          className: classes.selectBox,
        }}
      >
        {months.map((month, i) => (
          <MenuItem value={i + 1}>{month}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default MonthField
