import React from "react"
import history from "../history"
interface Props {
  title?: string
}

class StaticPage extends React.Component<Props> {
  public render() {
    const { title, children } = this.props
    return (
      <div className="flex" style={{ backgroundColor: "white" }}>
        <div className="flex flex-col w-full self-center">
          <div className="flex justify-between bunna-background back-ground-header">
            <div className="m-12 md:m-16">
              <img
                className="cursor-pointer"
                onClick={() => history.goBack()}
                style={{ width: 30, height: 20 }}
                src="/back.png"
              ></img>
            </div>
            <a href="/">
              <img className="app-logo" src="/bunna.png"></img>
            </a>
            <div className="m-32" />
          </div>
          {title && (
            <h2 className="static-page-title font-fcd text:3xl font-bebas">
              {title}
            </h2>
          )}
          <div className="static-page-content">{children}</div>
        </div>
      </div>
    )
  }
}

export default StaticPage
