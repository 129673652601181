import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import CustomButton from "@components/CustomButton"
import CustomInput from "@components/CustomInput"
import WinnerModal from "@components/WinnerModal"
import { validateEmail } from "@helpers/UtilsHelper"

import { addUserData } from "@redux/competitionData/CompetitionAction"
import {
  CustomButtonStyles,
  InformationFormStyles,
} from "../../../styles/Components"
import CountrySelector from "./CountrySelector"

const InformationForm = ({ toggleErrorPage, toggleThankYouPage }) => {
  const [email, setEmail] = useState("")
  const [firstName, setfirstName] = useState("")
  const [lastName, setlastName] = useState("")
  const [isChecked, setisChecked] = useState(false)
  const [code, setCode] = useState("")
  const [isSecondChecked, setisSecondChecked] = useState(false)
  const [isGiveawayChecked, setisGiveawayChecked] = useState(false)
  const [emailErrorMessage, setemailErrorMessage] = useState("")
  const [firstNameErrorMessage, setfirstNameErrorMessage] = useState("")
  const [lastNameErrorMessage, setislastNameErrorMessage] = useState("")
  const [codeErrorMessage, setcodeErrorMessage] = useState("")
  const [selected, setSelected] = useState({
    value: "GB",
    label: "United Kingdom",
  })
  const dispatch = useDispatch()
  const { date, status, title, loading, rewarded, image } = useSelector(
    ({ competitionData }: RootState) => competitionData,
  )

  useEffect(() => {
    if (status === 1 || status === 2) {
      setcodeErrorMessage(title)
    }

    if (status === 3) {
      toggleThankYouPage()
    }
  }, [status])

  const canBeSubmitted = () => {
    return (
      firstNameErrorMessage.length < 1 &&
      lastNameErrorMessage.length < 1 &&
      emailErrorMessage.length < 1 &&
      codeErrorMessage.length < 1 &&
      firstName &&
      firstName.length > 0 &&
      lastName &&
      lastName.length > 0 &&
      email &&
      email.length > 0 &&
      code &&
      code.length > 0 &&
      isChecked
    )
  }
  const handleCountryChange = (e) => {
    setSelected(e)
  }

  const handleCheckChange = () => {
    setisChecked(!isChecked)
  }
  const handleEmailBlur = () => {
    if (email === "" || !validateEmail(email)) {
      setemailErrorMessage("Please enter email")
    } else {
      setemailErrorMessage("")
    }
  }
  const handleEmailChange = (e) => {
    setEmail(e.target.value)

    if (email || email.length < 1 || !validateEmail(email)) {
      setemailErrorMessage("Please enter valid email")
      return
    }
    setemailErrorMessage("")
  }
  const handleFirstNameBlur = () => {
    if (firstName === "") {
      setfirstNameErrorMessage("please enter first name")
    } else {
      setfirstNameErrorMessage("")
    }
  }

  const handleLastNameBlur = () => {
    if (lastName === "") {
      setislastNameErrorMessage("Please enter last name")
    } else {
      setislastNameErrorMessage("")
    }
  }

  const handleCodeBlur = () => {
    if (code === "") {
      setcodeErrorMessage("Please enter promotional code")
    } else {
      setcodeErrorMessage("")
    }
  }

  const secondHandleCheckChange = () => {
    setisSecondChecked(!isSecondChecked)
  }
  const giveawayHandleCheckChange = () => {
    setisGiveawayChecked(!isGiveawayChecked)
    toggleErrorPage()
  }

  const handleSubmit = () => {
    const obj = {
      first_name: firstName,
      last_name: lastName,
      date,
      email,
      code,
      country: selected.label,
      isChecked,
      isSecondChecked,
      isGiveawayChecked,
    }
    dispatch(addUserData(obj))
  }
  return (
    <div className="delay fade">
      <div className="flex justify-center">
        <span
          style={{
            fontFamily: "font-bebas",
            color: "#fff",
            margin: "15px 5px",
            fontSize: 14,
            textAlign: "center",
            wordSpacing: "0.3em",
          }}
        >
          Enter your details to check if you’re a winner
        </span>
      </div>
      <div className="flex justify-center">
        <div style={{ marginTop: 12, marginBottom: 5 }}>
          <CustomInput
            large
            error={codeErrorMessage.length > 0}
            value={code}
            maxLength={100}
            autoFocus
            placeholder="Promotion code"
            onBlur={handleCodeBlur}
            onChange={(e) => {
              setcodeErrorMessage("")
              setCode(e.target.value)
            }}
            errorMessage={codeErrorMessage}
          />
        </div>
      </div>
      <div
        className="flex justify-center"
        style={InformationFormStyles.rowMarginTop}
      >
        <div style={InformationFormStyles.inputMarginRight}>
          <CustomInput
            error={firstNameErrorMessage.length > 0}
            medium
            value={firstName}
            name="firstName"
            maxLength={30}
            placeholder="First Name"
            onBlur={handleFirstNameBlur}
            onChange={(e) => setfirstName(e.target.value)}
            errorMessage={firstNameErrorMessage}
          />
        </div>
        <div style={InformationFormStyles.inputMarginLeft}>
          <CustomInput
            medium
            error={lastNameErrorMessage.length > 0}
            value={lastName}
            name="lastName"
            maxLength={30}
            placeholder="Last Name"
            onBlur={handleLastNameBlur}
            onChange={(e) => setlastName(e.target.value)}
            errorMessage={lastNameErrorMessage}
          />
        </div>
      </div>
      <div
        className="flex justify-center"
        style={InformationFormStyles.rowMarginTop}
      >
        <div style={{ marginTop: 12, marginBottom: 5 }}>
          <CustomInput
            large
            error={emailErrorMessage.length > 0}
            value={email}
            maxLength={100}
            placeholder="Email"
            onBlur={handleEmailBlur}
            onChange={handleEmailChange}
            errorMessage={emailErrorMessage}
          />
        </div>
      </div>
      <div
        className="flex justify-center"
        style={InformationFormStyles.rowMarginTop}
      ></div>

      <div className="flex justify-center" style={CustomButtonStyles.dropDown}>
        <CountrySelector onChange={handleCountryChange} value={selected} />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div className="flex flex-col" style={{ alignItems: "baseline" }}>
          <div className="flex justify-center align-center mt-20 mx-10">
            <label
              className="checkbox-container"
              style={{
                color: "#fff",
                fontSize: 12.5,
                textAlign: "left",
              }}
            >
              I confirm I have read and understood the terms & conditions of
              this promotion
              <input
                type="checkBox"
                onChange={handleCheckChange}
                checked={isChecked}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div className="flex justify-center align-center  mx-10">
            <label
              className="checkbox-container"
              style={{
                color: "#fff",
                fontSize: 12.5,
                textAlign: "left",
              }}
            >
              I would like to recieve email updates and news from the
              distillery.
              <input
                type="checkBox"
                onChange={secondHandleCheckChange}
                checked={isSecondChecked}
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div className="flex justify-center align-center mx-10">
            <label
              className="checkbox-container"
              style={{
                color: "#fff",
                fontSize: 12.5,
                textAlign: "left",
              }}
            >
              I consent to receiving marketing communications from other Distell
              brands
              <input
                type="checkBox"
                onChange={giveawayHandleCheckChange}
                checked={isGiveawayChecked}
              />
              <span className="checkmark"></span>
            </label>
          </div>

          <p
            className="flex justify-center align-center mx-10 text-center inline"
            style={{
              color: "#fff",
              fontSize: 14,
              maxWidth: "24rem",
              display: "inline",
            }}
          >
            If you change your mind, you can unsubscribe at any time. For more
            information, please see our{" "}
            <a href="/privacy-policy">Privacy Policy</a>
          </p>
        </div>
      </div>

      <div
        className="flex justify-center"
        style={{ marginTop: 15, marginBottom: 20 }}
      >
        <CustomButton
          className={canBeSubmitted() ? "button" : "disable_button"}
          text={"Submit"}
          large
          disabled={canBeSubmitted() ? false : true || loading}
          onClick={handleSubmit}
        ></CustomButton>
      </div>
      <WinnerModal
        isOpen={rewarded}
        title={title}
        closeModal={toggleThankYouPage}
        image={image}
      />
    </div>
  )
}
export default InformationForm
