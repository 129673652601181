import React from "react"

export const OffTrade = () => {
  return (
    <>
      <ol>
        <li>
          These Terms and Conditions prevail in the event of any conflict or
          inconsistency with any other communications, including advertising or
          promotional materials. Entry and claim instructions are deemed to form
          part of the Terms and Conditions and by participating, all entrants
          will be deemed to have accepted and be bound by the Terms and
          Conditions. Please retain a copy for your information.
        </li>
        <li>
          Promoter: Distell International Limited, 8 Milton Rd, College Milton
          North, East Kilbride, Glasgow G74 5BU.
        </li>
        <li>
          This promotion is open to GB residents aged 18 or over, excluding
          employees and the immediate families of the Promoter, its
          subsidiaries, other group companies, its agents, suppliers, or anyone
          else professionally connected with this promotion. Excludes the
          Channel Islands and the Isle of Man.
        </li>
        <li>The promotion will run from 09:00 01/05/21 to 23:59 30/06/21.</li>
        <li>Internet access required.</li>
        <li>
          Purchase of a promotional 70cl bottle of Bunnahabhain Stiuireadair
          from a participating outlet is required, whilst stocks last.
        </li>
        <li>Please retain your receipt as proof of purchase.</li>
        <li>
          Enter your unique code (found inside the promotional leaflet, inside
          the tube) at the online microsite address{" "}
          <a target="_blank" href="http://www.discoverthesoundofislay.co.uk">
            www.discoverthesoundofislay.co.uk
          </a>{" "}
          to enter the prize draw and online instant win promotion - only valid
          online entries will be counted. Entrants will be asked to enter their
          name, email address and postcode alongside their unique code. Entrants
          are advised to take care when submitting an entry. Only correctly
          submitted entries will be accepted. Entries received after the closing
          date will not be entered.
        </li>
        <li>
          Each promotional code is unique and can only be used once. Any
          attempts to enter the same code more than once or the use of any codes
          not intended for use in this promotion, may result in disqualification
          and all such entries will be declared void.
        </li>
        <li>
          Entrants who make an entry on someone else’s behalf will be
          disqualified, at the Promoter’s discretion.
        </li>
        <li>
          Entries (bulk or otherwise) made from trade, consumer groups or third
          parties will not be accepted. If it becomes apparent that a
          participant is reposting the same content multiple times or using a
          computer(s) to circumvent this or any other condition by, for example,
          the use of ‘script’, ‘brute force’ or any other automated means, that
          person’s entries will be disqualified and any prize award will be
          void.
        </li>
        <li>
          Incomplete, illegible, invalid, misdirected or late entries will not
          be accepted. Proof of sending will not be accepted as proof of
          delivery. The Promoter takes no responsibility for entries delayed,
          incomplete or lost due to technical reasons or otherwise.
        </li>
        <li>
          Prizes:
          <ul>
            <li>
              <strong>Hero Prize: </strong>1 x VIP Festival Experience on Islay
              (for Winner and 1 guest over the age of 18. Guests must travel
              together):
              <ul>
                <li>
                  Includes return economy flights from Glasgow or Edinburgh,
                  return regional connecting flights OR an additional night’s
                  accommodation. Return private transfers from the
                  airport/Islay. 2 nights’ accommodation at Islay House or
                  similar 4-star hotel, sharing a twin/double room including
                  daily breakfast. Three course meal on one evening including
                  alcoholic and non alcoholic drinks. Return private transfers
                  between hotel and distillery. Comprehensive travel insurance.
                  It is the responsibility of the winner and/or their companion
                  to meet any additional costs relating to obtaining insurance
                  cover for any pre-existing medical condition and for any
                  additional costs as may be specified where the winner and/or
                  their companion are aged 70 or over. The prize does not
                  include spending money, tax or personal expenses. Any other
                  costs incurred in addition to those set out above and that are
                  incidental to the fulfilment of the prize are the
                  responsibility of the winner.
                </li>
                <li>
                  Includes Feis Ile ticket for the morning Masterclass with
                  Andrew (distillery manager) and Julieann (master blender)
                  (approx. 90min from approx. 11.00am). Backstage pass to speak
                  with Andrew and Julieann after the tasting. Ticket for free
                  lunch. Ticket for an afternoon Warehouse 9 session (approx.
                  1hr long). Ticket for the Bunnahabhain at Sea fast RIB trip
                  (approx. 30min) Free Feis Ile Bunnahabhain limited edition
                  bottle.
                </li>
                <li>
                  Trip date will be the end of May 2022 to coincide with the
                  Feis Ile festival on Islay. Exact festival date is TBC and
                  will determine the winner’s travel dates. Once booked, the
                  trip has a non flexible cancellation policy.
                </li>
              </ul>
            </li>
            <li>
              <strong>Secondary Online Instant Win Prizes: </strong>201
              adventure essential prizes including 5 x Garmin Venu smartwatches
              (worth £340 each), 5 x SONY A6000 Compact cameras (worth £510
              each), 10 x Patagonia Blackhole 25L rucksacks (worth £120 each),
              181 branded Bunnahabhain water bottles.
            </li>
          </ul>
        </li>
        <li>
          One entry per customer per week. Anyone entering more than once using
          multiple identities or computerised or automated system will be
          disqualified from the promotion. Maximum of one secondary prize per
          person, the hero prize winner may win max one secondary prize . No
          guarantee all online instant prizes will be won.
        </li>
        <li>
          No cash or other alternative prizes will be provided in whole or in
          part, except that in the event of circumstances outside of its control
          the Promoter reserves the right to substitute similar prizes of equal
          or greater value. The prizes are non-transferable.
        </li>
        <li>
          The Promoter encourages responsible drinking and would direct
          consumers to read{" "}
          <a target="_blank" href="http://www.drinkaware.co.uk/">
            http://www.drinkaware.co.uk/
          </a>{" "}
          for more information.
        </li>
        <li>
          Winners will be determined via two methods for each prize fund,
          detailed below:
          <ul>
            <li>
              <strong>Hero Prize Draw: </strong>winner selected from all valid
              entries by a randomised computer process. The draw will take place
              by 5pm on 01.07.21.
            </li>
            <li>
              <strong>Secondary Online Instant Win Prizes: </strong>entrant will
              be notified instantly on the microsite if they are a winner of a
              secondary prize via time stamped winning moments mechanic. 201
              winning moments have been randomly allocated across the
              promotional period selected by a secure, independently-verified
              computer programme. If no entry is received between one winning
              moment and the next, the prize from that winning moment will be
              rolled over into the next winning moment and be available to be
              won then. No guarantee all prizes will be won during the main
              promotional period.
              <ul>
                <li>
                  In cases where responses are not received in time or the
                  winner is ineligible or rejects the prize, another winner will
                  be selected during a wrap up draw after the promotional period
                  ends. Winners will be selected from all losing valid entries
                  by a randomised computer process. The wrap up draw will take
                  place by 5pm on 29.07.21. Winners of the wrap up draw will be
                  notified via the email address used for entry and will be
                  supplied with ‘how to claim’ details by 6pm on 29.07.21.
                  Winners have 10 working days to respond by providing their
                  DOB, phone number (if winner of the hero prize) and postal
                  address (if winner of a secondary prize). Age will be verified
                  by asking for a scanned copy of their photographic ID (valid
                  drivers license or passport accepted). Purchase will be
                  verified by requesting proof of purchase. In the case that
                  they do not respond in time or are ineligible another winner
                  will be selected using the same methodology as above. This
                  process will be repeated until an eligible winner is able to
                  claim the prize.
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          Winners of the <strong>prize draw </strong>will be notified via the
          email address used for entry and will be supplied with ‘how to claim’
          details (by 5pm on 01.07.21). Winners have 10 working days to respond
          by providing their DOB, phone number and postal address. Age will be
          verified by asking for a scanned copy of their photographic ID (valid
          drivers license or passport accepted). Purchase will be verified by
          requesting proof of purchase. In cases where responses are not
          received in time or the winner is ineligible or rejects the prize,
          another winner will be selected using the same methodology as above.
          This process will be repeated until an eligible winner is able to
          claim the prize. Entrants are encouraged to monitor their email
          connections during this time in case they are the winner.
        </li>
        <li>
          Winners will be contacted via email to redeem the hero prize from the
          draw and tickets for the event will be provided via email. The Feis
          Ile Bunnahabhain limited edition whisky will be sent alongside their
          printed invitation to the event via second class post within 2021
          after the completion of the verification process.
        </li>
        <li>
          Winners of the <strong>online instant win prizes </strong>will be
          notified via the email address used for entry and will be immediately
          supplied with ‘how to claim’ details. Winners have 10 working days to
          respond by providing their DOB, phone number and postal address. Age
          will be verified by asking for a scanned copy of their photographic ID
          (valid drivers license or passport accepted). Purchase will be
          verified by requesting proof of purchase. Entrants are encouraged to
          monitor their email connections during this time in case they are the
          winner. In cases where responses are not received in time or the
          winner is ineligible or rejects the prize, the prize will be
          reallocated to the wrap up draw (see clause 17).
        </li>
        <li>
          Winners will receive their prize from the instant win within 28 days
          of the promoter’s receipt of valid how to claim details and the
          completion of any verification process via second class post
        </li>
        <li>
          If fulfilment or any element of this promotion will be delayed or
          affected due to the continuing Covid-19 pandemic and resulting
          Government restrictions, all entrants affected will be contacted by
          the Promoter and kept updated of any unavoidable changes to the
          promotional dates, revised fulfilment dates or prize.
        </li>
        <li>
          It is the responsibility of the entrant to provide correct, up-to-date
          details when entering the promotion and acceptance of the prize. The
          Promoter cannot be held responsible for winners failing to supply
          accurate information which affects prize acceptance or delivery of
          their prize.
        </li>
        <li>
          The Promoter reserves the right to verify all entries including but
          not limited to asking for proof of purchase, address and ID which the
          entrant must provide within 10 days. The Promoter reserves the right
          to refuse to award a prize or withdraw prize entitlement and/or refuse
          further participation in the promotion and disqualify the participant
          where there are reasonable grounds to believe there has been a breach
          of these Terms and Conditions, the spirit of the promotion, any
          instructions forming part of this promotion’s entry requirements or
          otherwise where a participant has gained unfair advantage in
          participating or won using fraudulent means.
        </li>
        <li>
          The Promoter will make available the Hero Prize winner’s surname and
          county to members of the public or regulators who request such details
          within 3 months of the closing date of this promotion. Entrants can
          object to disclosure, or request that disclosure be limited in scope
          by contacting{" "}
          <a href="mailto:support@discoverthesoundofislay.co.uk">
            support@discoverthesoundofislay.co.uk
          </a>
          . We may nevertheless disclose the information to the Advertising
          Standards Authority if required to do so. You may request a copy of
          the winner’s details by emailing{" "}
          <a href="mailto:support@discoverthesoundofislay.co.uk">
            support@discoverthesoundofislay.co.uk
          </a>{" "}
          . We reserve the right to refuse any or all such requests.
        </li>
        <li>
          Prizes cannot be sold. Winning prizes cannot be combined with other
          discounts, cashback activities or promotions. The prizes are not
          refundable and cannot be exchanged for cash or other goods and
          services.
        </li>
        <li>
          Offer not valid in conjunction with any other offers, promotions or
          voucher codes. This promotion is not valid in stockists that aren’t
          taking part in this promotion.
        </li>
        <li>
          The Promoter reserves the right to substitute any free item included
          in the Hero Prize with an item of equal or greater value. In the event
          of any dispute, the decision of the Promoter is final.
        </li>
        <li>
          If for any reason any aspect of this promotion is not capable of
          running as planned, the Promoter may in its sole discretion modify or
          suspend the promotion or invalidate any affected entries. If an act,
          omission, event or circumstance occurs which is beyond the reasonable
          control of the Promoter and which prevents the Promoter from complying
          with these terms and conditions the Promoter will not be liable for
          any failure to perform or delay in performing its obligation.
        </li>
        <li>
          The Promoter and its associated agencies and companies will not be
          liable for any loss (including, without limitation, indirect, special
          or consequential loss or loss of profits), expense or damage which is
          suffered or sustained (whether or not arising from any person’s
          negligence) in connection with this promotion or accepting or using
          the prize, except for any liability which cannot be excluded by law
          (including personal injury, death and fraud) in which case that
          liability is limited to the minimum allowable by law.
        </li>
        <li>
          The Promoter has no control over internet or communication networks
          and is not liable for any problems associated with them due to traffic
          congestion, technical malfunction or otherwise. The Promoter will not
          be held liable to any individual for any fraud committed by any third
          party nor for any event beyond its control including, but not limited
          to, user error and any network, computer, hardware or software
          failures of any kind which may restrict, delay or prevent a
          participant’s entry to the promotion.
        </li>
        <li>
          If any of these clauses should be determined to be illegal, invalid or
          otherwise unenforceable then it shall be severed and deleted from
          these terms and conditions and the remaining clauses shall survive and
          remain in full force and effect.
        </li>
        <li>
          The Promoter will only use the personal details supplied for the
          administration of the promotion and for no other purpose, unless we
          have your consent. Your personal details will at all times be kept
          confidential and in accordance with current Data Protection
          legislation. Click{" "}
          <a href="https://bunnahabhain.com/privacy-policy/">
            https://bunnahabhain.com/privacy-policy/
          </a>{" "}
          for the Promoter’s Privacy Policy. You can request access to your
          personal data, or have any inaccuracies rectified, by sending an email
          to{" "}
          <a href="mailto:data.privacy@distellinternational.com">
            data.privacy@distellinternational.com.
          </a>{" "}
          By participating in the Promotion, you agree to the use of your
          personal data as described here.
        </li>
        <li>
          These terms and conditions and any related disputes are subject to
          interpretation under the laws of England and Wales, and to the
          jurisdiction of the courts of England and Wales unless your
          residential address is in Scotland, when Scottish law applies.
        </li>
      </ol>
    </>
  )
}
