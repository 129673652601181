export const CompetitionData = {
  SEND_USER_COMPETITION_DATA_START: "SEND_USER_COMPETITION_DATA_START",
  SEND_USER_COMPETITION_DATA_SUCCESS: "SEND_USER_COMPETITION_DATA_SUCCESS",
  SEND_USER_COMPETITION_DATA_FAIL: "SEND_USER_COMPETITION_DATA_FAIL",

  SUBSCRIBE_NEWSLETTER_START: "SUBSCRIBE_NEWSLETTER_START",
  SUBSCRIBE_NEWSLETTER_SUCCESS: "SUBSCRIBE_NEWSLETTER_SUCCESS",
  SUBSCRIBE_NEWSLETTER_FAIL: "SUBSCRIBE_NEWSLETTER_FAIL",
}

export const ActionTypeDate = {
  SET_DATE: "SET_DATE",
}
