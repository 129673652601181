import { CompetitionData, ActionTypeDate } from "../redux/ActionTypes"
const INITIAL_STATE = {
  data: [],
  date: "",
  status: 0,
  rewarded: false,
  image: "",
  description: "",
  title: "",
  reward: "",
  loading: false,
  newsLetterSubscribed: false
}
const CompetitionDataReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CompetitionData.ADD_USER_COMPETITION_DATA_START: {
      return { ...state }
    }
    case CompetitionData.ADD_USER_COMPETITION_DATA_SUCCESS: {
      return {
        ...state,
        data: action.payload,
      }
    }
    case CompetitionData.ADD_USER_COMPETITION_DATA_FAIL: {
      return {
        ...state,
        data: action.payload,
      }
    }

    case CompetitionData.SEND_USER_COMPETITION_DATA_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    }

    case CompetitionData.SEND_USER_COMPETITION_DATA_FAIL: {
      return {
        ...state,
        ...action.payload,
        loading: false,
      }
    }

    case CompetitionData.SEND_USER_COMPETITION_DATA_START: {
      return {
        ...state,
        status: 0,
        description: "",
        title: "",
        loading: true,
      }
    }

    case ActionTypeDate.SET_DATE: {
      return {
        ...state,
        date: action.payload,
      }
    }

    case CompetitionData.SUBSCRIBE_NEWSLETTER_START: {
      return {
        ...state,
        newsLetterSubscribed: false,
      }
    }

    case CompetitionData.SUBSCRIBE_NEWSLETTER_FAIL: {
      return {
        ...state,
        newsLetterSubscribed: true,
      }
    }

    case CompetitionData.SUBSCRIBE_NEWSLETTER_SUCCESS: {
      return {
        ...state,
        newsLetterSubscribed: true,
      }
    }

    default: {
      return state
    }
  }
}
export default CompetitionDataReducer
