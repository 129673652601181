import React from "react"

import StaticPage from "@components/StaticPage"
import { OffTrade } from "./components/OffTrade"
// import { OnTrade } from "./components/OnTrade"
class TermsAndConditions extends React.Component<{}> {
  public render() {
    return (
      <React.Fragment>
        <StaticPage title="TERMS & CONDITIONS">
          <OffTrade />
          <div className="separator"></div>
          {/* <OnTrade />
          <div className="separator"></div> */}
        </StaticPage>
      </React.Fragment>
    )
  }
}

export default TermsAndConditions
