import React from "react";
import { Route } from "react-router-dom";
import Layout from "./Layout";
import { Redirect } from "react-router-dom";
const CustomRoute = (props) => {

    return (
        <Route
            path={props.path}
            exact={props.exact}
            component={() => (
                <Layout>
                    <props.component />
                </Layout>
            )}
        />
    );
};
export default CustomRoute;