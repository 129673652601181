import React from "react"
import { useSelector } from "react-redux"
// import ReactGA from "react-ga"

const ThankYouPage = () => {
  const { rewarded, status } = useSelector(
    ({ competitionData }) => competitionData,
  )
  const emailClicked = () => {
    // ReactGA.event({
    //   category: "LINK CLICKED",
    //   action: `clicked support email on thank you page`,
    //   label: "SUPPORT EMAIL",
    // })
  }

  const webClicked = () => {}

  return (
    <div className="delay fade">
      <div className="flex justify-center">
        <span className="thank__you">THANK YOU</span>
      </div>
      {rewarded && (
        <div className="flex justify-center">
          <span
            className="mx-24"
            style={{
              color: "#fff",
              marginTop: 10,
              fontSize: 18,
              textAlign: "center",
            }}
          >
            We will be in touch to arrange delivery of your prize in the next 48
            hours. Discover more on Fèis Ìle and Bunnahabhain Single Malt{" "}
            <a href="https://bunnahabhain.com/">here</a>
          </span>
        </div>
      )}
      {status === 3 && (
        <>
          <div className="flex justify-center">
            <span
              style={{
                color: "#fff",
                marginTop: 10,
                fontSize: 24,
                textAlign: "center",
              }}
            >
              Sorry you're not a winner today!
            </span>
          </div>

          <div className="flex justify-center">
            <span
              className="mx-12 md:32"
              style={{
                fontSize: 18,
                color: "#fff",
                marginTop: 15,
                textAlign: "center",
              }}
            >
              But you're still in with a chance to win our main prize of a VIP
              Islay Festival Experience.  The draw for this will take place at
              the end of the promotion. We will be in touch with you if you are
              a winner. Discover more on Fèis Ìle and Bunnahabhain Single Malt{" "}
              <a href="https://bunnahabhain.com" target="_blank">
                here
              </a>
            </span>
          </div>
        </>
      )}
      <div className="flex justify-center">
        <span
          style={{
            color: "#fff",
            margin: "15px 0px",
            fontSize: 20,
            textAlign: "center",
          }}
        >
          For any queries please contact{" "}
          <a
            target="_blank"
            href="mailto:support@discoverthesoundofislay.co.uk?subject=Bunnahabhain Competition"
            onClick={emailClicked}
          >
            support@discoverthesoundofislay.co.uk
          </a>
        </span>
      </div>
      <div className="flex justify-center">
        <span
          style={{
            color: "#fff",
            margin: "5px 0px 25px 0px",
            fontSize: 20,
            textAlign: "center",
          }}
        >
          For more on Bunnahabhain go to{" "}
          <a
            href="https://bunnahabhain.com"
            target="_blank"
            onClick={webClicked}
          >
            www.bunnahabhain.com
          </a>
        </span>
      </div>
    </div>
  )
}

export default ThankYouPage
