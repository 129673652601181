import React, { useMemo } from "react"
import Select from "react-select"
import countryList from "react-select-country-list"

function CountrySelector({ onChange, value }) {
  const options = useMemo(() => countryList().getData(), [])

  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
      borderRadius: "none",
      padding: "8px",
      minHeight: 32,
    }),
    container: (base) => ({
      ...base,
    }),
    menu: (styles) => ({
      ...styles,
      height: "200px",
      overflowY: "hidden",
    }),
  }

  return (
    <Select
      options={options}
      value={options.filter((option) => option.label === value.label)}
      onChange={onChange}
      styles={style}
    />
  )
}

export default CountrySelector
