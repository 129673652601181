import Footer from "@components/Footer"
import React from "react"
import classes from "./footer.module.css"

const AppFooter = () => {
  return (
    <div className={classes.main__container}>
      <div className={classes.footer}>
        <div className={classes.first__img__container}>
          <a href="https://bunnahabhain.com">
            <img src="/logo-footer.svg" className={classes.footerlogo} />
          </a>
          <img src="sailor.png" className={classes.bunnah} />
        </div>
        <div className={classes.second__container}>
          <h6 className={classes.title}>Navigation</h6>
          <a href="/" className={classes.content}>
            Competition
          </a>
          <p>
            <a href="/faq" className={classes.content}>
              FAQs
            </a>
          </p>
        </div>
        <div className={classes.third__container}>
          <h6 className={classes.title}>Get In Touch</h6>
          <p className={classes.content}>
            <a
              href="mailto:support@discoverthesoundofislay.co.uk?subject=Bunnahabhain Competition"
              className={classes.content}
            >
              Email: support@discoverthesoundofislay.co.uk
            </a>
          </p>
        </div>
      </div>
      <Footer />
      <p className={classes.drinkaware}>
        For the facts{"  "}
        <a
          href="http://www.drinkaware.co.uk"
          className={classes.drinkaware__link}
        >
          www.drinkaware.co.uk
        </a>
      </p>
    </div>
  )
}

export default AppFooter
