import React from "react"

import StaticPage from "@components/StaticPage"

class PrivacyPolicy extends React.Component<{}> {
  public render() {
    return (
      <StaticPage title="PRIVACY POLICY">
        <h6>INTRODUCTION</h6>
        <p>
          All references to “our”, “us”, “we” or “company” within this policy
          and within the opt-in notice are deemed to refer to Distell Group Ltd,
          its subsidiaries, affiliates and associates.
        </p>
        <p>
          We endeavour to protect your personal privacy. We provide this Privacy
          Policy to help you to understand what we may do with any personal
          information that we obtain from you. By providing your personal
          information to us, you signify your acceptance of our policy and agree
          that we may collect, use and disclose your personal information as
          described in this policy. If you do not agree to this policy, please
          do not provide your personal details to us. This policy is
          incorporated into, and part of, the Terms & Conditions, which govern
          your use of the site in general. We will use your information only for
          the purposes set out below.
        </p>
        <p>
          We may need to update this policy from time to time and recommend that
          you regularly check this page to ensure that you have read the most
          recent version.
        </p>
        <h6>LEGAL PURCHASE AGE</h6>
        <p>
          You must not provide us with your personal information if you are not
          of legal age to purchase alcohol in the jurisdiction in which you
          reside and (if different) in the jurisdiction in which you are
          accessing the site. We do not intend to collect personal information
          from any individuals under the legal purchase age or market alcoholic
          beverages to anyone under the legal purchase age. If we receive notice
          or believe that someone under the legal purchase age has provided us
          with personal information we will make every reasonable effort to
          remove such personal information from our files or flag such personal
          information and retain it for the sole purpose of ensuring that it is
          not used any further.
        </p>
        <h6>WHAT IS PERSONAL INFORMATION?</h6>
        <p>
          Personal information is information about an identifiable individual,
          as defined by applicable law. It does not include aggregated
          information that does not allow you to be identified, nor does it
          include business contact information such as your name, title,
          business address and business phone number.
        </p>
        <h6>COLLECTION AND USE OF YOUR PERSONAL DATA</h6>
        <p>
          When you use this website, you may provide us with personal data, for
          example by completing online forms, online applications, or online
          order forms. We will only use your personal data for the purposes of
          dealing with your enquiry, the administration of any orders, the
          provision of any goods or services we offer, the administration of any
          account or membership held by you, answering questions or complaints
          you may have in relation to our goods or services, for internal
          marketing analysis, or (if you agree) to provide you with details of
          any new or improved products or services we may be offering from time
          to time (and you will be entitled to opt-out of any such
          communications at any time). We may also contact you at a later date
          to seek your consent to use of your personal data for different
          purposes. We will only use your personal data for the purpose of which
          you provided it, or for any other purpose that you expressly consent
          to. We may also collect personal data by using “cookies”. For more
          information about cookies visit our{" "}
          <a target="_blank" href="/cookie-policy">
            cookies
          </a>{" "}
          page or
          <a target="_blank" href="https://www.allaboutcookies.org/">
            www.allaboutcookies.org
          </a>{" "}
          for assistance with adjusting your browser settings. We do not use any
          other web tracking software, web bugs or similar.
        </p>
        <h6>SHARING YOUR PERSONAL DATA</h6>
        <p>
          We will not disclose any personal data which is provided by you
          through our website to any third party without your permission, other
          than (i) to any sub-contractors or agents engaged by us to provide any
          support or administration, facilities management or similar services
          (subject to such sub-contractors and/or agents undertaking to keep
          such personal data confidential); (ii) to any company or organisation
          to which we transfer our responsibilities to provide the goods or
          services to you; or (iii) where required by law or made in connection
          with legal or regulatory proceedings. We will not transfer your
          personal data outwith the European Economic Area (“EEA”) without your
          prior consent. If we do transfer your personal data outwith the EEA,
          we will use reasonable endeavours to assess the adequacy or the
          protections afforded in respect of personal data under the laws of the
          particular country concerned.
        </p>
        <h6>SECURITY</h6>
        <p>
          We will not hold your personal data for any longer than is reasonable
          for the above purposes. We have taken reasonable precautions to keep
          your personal information secure. However you should be aware that any
          personal data submitted by you via the internet is at your own risk.
        </p>
        <strong>UNSUBSCRIBE, ACCESS, QUESTIONS AND FURTHER INFORMATION</strong>
        <p>
          At your request, where the law requires us to do so, we will confirm
          what personal information we hold about you, update your information,
          remove your information and/or correct any inaccuracies in such
          personal information if you contact us using the unsubscribe details
          provided below.
        </p>
        <p>
          If you would like to unsubscribe from communications about Distell
          Group Ltd, or if you have any privacy-related questions, concerns or
          complaints, please get in touch via <a href="#">our contact form.</a>
        </p>
        <h6>LINKS</h6>
        <p>
          This site may contain links or references to other websites outside of
          our control. Please be aware that we have no control over these sites
          and our privacy statement does not apply to these sites. We encourage
          you to read the privacy statements and terms and conditions of linked
          or referenced sites you enter.
        </p>
        <p>
          As required, this policy will be supplemented by additional legal
          requirements in jurisdictions where we conduct business. Nothing in
          this policy or otherwise will create, or add to, any right or claim
          (whether legal, equitable or otherwise) that any individual or person
          may have at law or otherwise against the company or any third party or
          any of their respective directors, officers, employees, agents or
          representatives, nor will the existence of this policy or its
          application impose or add to any obligations or liability upon the
          company that the company does not already otherwise have under law.
        </p>
        <p>
          Distell Group Ltd is the owner of the Bunnahabhain brand and is
          therefore the data controller for this website. Distell owns several
          well-known brands in the alcoholic drinks sector and distributes all
          over the world.
        </p>
        <p>The company is registered in Scotland with headquarters at:</p>
        <div className="flex flex-col">
          <strong>8 Milton Road</strong>
          <strong>College Milton North</strong>
          <strong>East Kilbride</strong>
          <strong>G74 5BU</strong>
          <strong>Scotland</strong>
        </div>
        <p>Registered No: SC109881</p>
        <a target="_blank" href="https://www.distell.co.za/home/">
          https://www.distell.co.za/home/
        </a>
        <p>
          If you have any questions regarding this privacy policy, please
          contact
        </p>
        <a target="blank" href="mailto:data.privacy@distellinternational.com">
          data.privacy@distellinternational.com
        </a>
        <p>To contact us please use the phone numbers below:</p>
        <div className="flex flex-col">
          <strong>Tel: +44(0)1355 260999</strong>
          <strong>Fax: +44(0)1355 264355</strong>
        </div>
      </StaticPage>
    )
  }
}

export default PrivacyPolicy
