import React, { Component } from "react"
import { connect } from "react-redux"

import AppFooter from "@modules/home/component/AppFooter"
import Header from "@modules/home/component/Header"
import ComingSoon from "./component/ComingSoon"
import DateOfBirth from "./component/DateOfBirth"
import InformationForm from "./component/InformationForm"
import ThankYouPage from "./component/ThankYouPage"
interface State {
  showComing: boolean
  showDate: boolean
  showForm: boolean
  showThankYouPage: boolean
}
type Props = ReturnType<typeof mapStateToProps>
class HomePage extends Component<Props, State> {
  public state = {
    showComing: true,
    showDate: false,
    showForm: false,
    showThankYouPage: false,
  }

  public render() {
    const { showDate, showForm, showThankYouPage, showComing } = this.state
    return (
      <div className="home-background-image min-h-screen flex-column">
        <Header />
        <div className="flex justify-center flex-col w-full overlay min-h-screen">
          <img
            alt="black bottle logo"
            className="picture__logo"
            src="/bunna.png"
          ></img>

          {showComing && <ComingSoon />}

          {showDate && (
            <DateOfBirth toggleDateOfBirth={this.toggleDateOfBirth} />
          )}

          {showForm && (
            <InformationForm
              toggleErrorPage={this.toggleErrorPage}
              toggleThankYouPage={this.toggleThankYouPage}
            />
          )}
          {showThankYouPage && <ThankYouPage />}
        </div>
        <AppFooter />
      </div>
    )
  }

  public toggleDateOfBirth: any = () => {
    this.setState({
      showForm: true,
      showDate: false,
      showThankYouPage: false,
    })
  }
  public toggleErrorPage = () => {
    this.setState({
      showForm: true,
      showDate: false,
      showThankYouPage: false,
    })
  }

  public toggleThankYouPage = () => {
    this.setState({
      showForm: false,
      showDate: false,
      showThankYouPage: true,
    })
  }
}
const mapStateToProps = () => {
  return {}
}
export default connect(mapStateToProps, {})(HomePage)
