import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import CustomButton from "@components/CustomButton"
import CustomInput from "@components/CustomInput"
import { validateEmail } from "@helpers/UtilsHelper"
import { subscribeEmail } from "@redux/competitionData/CompetitionAction"
import { InformationFormStyles } from "../../../styles/Components"

export default function ComingSoon() {
  const [email, setEmail] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const dispatch = useDispatch()

  const { newsLetterSubscribed } = useSelector(
    ({ competitionData }) => competitionData,
  )

  const handleSubmit = () => dispatch(subscribeEmail({ email }))

  const handleEmailBlur = () => {
    if (email === "") {
      setErrorMessage("Please enter email")
    } else if (!validateEmail(email)) {
      setErrorMessage("Please enter valid email")
    } else {
      setErrorMessage("")
    }
  }

  if (newsLetterSubscribed) {
    return (
      <div className="delay fade">
        <div className="flex justify-center">
          <span className="thank__you">THANK YOU</span>
        </div>
        <div className="flex justify-center">
          <span
            className="mx-12 md:mx-64 mt-12"
            style={{
              color: "#fff",
              textAlign: "center",
              fontSize: 17,
            }}
          >
            We will be in touch with you very soon.
          </span>
        </div>
      </div>
    )
  }

  return (
    <>
    <div className="flex justify-center">
        <span
          className="mx-12 md:mx-64 my-12"
          style={{
            color: "#fff",
            textAlign: "center",
            fontSize: 25,
          }}
        >
          This promotion has ended.
        </span>
      </div>
      {/* <div className="flex justify-center">
        <span
          className="mx-12 md:mx-64 my-12"
          style={{
            color: "#fff",
            textAlign: "center",
            fontSize: 21,
          }}
        >
          This promotion is not open until the 1st of the May.
        </span>
      </div>
      <div className="flex justify-center">
        <span
          className="mx-12 md:mx-64 mb-12"
          style={{
            color: "#fff",
            textAlign: "center",
            fontSize: 17,
          }}
        >
          Keep your code and leave your email address and we will remind you
          when the promotion starts.
        </span>
      </div>
      <div className="flex justify-center">
        <span
          style={{
            fontFamily: "Times New Roman",
            color: "#fff",
            fontSize: 17,
          }}
        >
          Your Bunnahabhain Team
        </span>
      </div>
      <div className="flex justify-center mt-12">
        <div style={InformationFormStyles.inputMarginRight}>
          <CustomInput
            error={errorMessage.length > 0}
            large
            value={email}
            name="email"
            maxLength={60}
            placeholder="Your Email Address"
            onChange={(e) => setEmail(e.target.value)}
            errorMessage={errorMessage}
            onBlur={handleEmailBlur}
          />
        </div>
      </div>
      <div className="flex justify-center mt-12">
        <CustomButton
          className={errorMessage.length < 1 ? "button" : "disable_button"}
          text={"Submit"}
          large
          disabled={errorMessage.length > 0}
          onClick={handleSubmit}
        />
      </div> */}
    </>
  )
}
