import React from "react"

import StaticPage from "@components/StaticPage"

class CookiePolicy extends React.Component<{}> {
  public render() {
    return (
      <React.Fragment>
        <StaticPage title={"COOKIE POLICY"}>
          <h6>COOKIES</h6>
          <p>
            Distell Group Ltd, in the same way as many other online services,
            uses ‘cookies’ to store and at times track information about you.
          </p>
          <p>
            Cookies are very small files that are stored on your
            computer/mobile/tablet. They are sent back and forth between the
            website’s server and your Internet browser to allow the website to
            remember actions you’ve made.
          </p>
          <p>
            We use cookies and other local storage means to help improve the
            performance of this website, measuring web traffic and tracking user
            journeys to identify what pages and topics are of specific interest
            to you. We also use a security cookie to identify our website’s
            developers. Most of these only contain randomly generated numbers,
            and cookies cannot carry viruses or install malware on your
            computer.
          </p>
          <p>
            Most major websites use cookies and their use is standard on the
            Internet. Most Internet browsers automatically accept cookies.
            However, you can change your settings so that you are notified
            whenever you are sent a cookie, or block them completely should you
            wish to do this.
          </p>
          <p>
            Please note, some services are only activated by the presence of a
            cookie and if you choose not to utilise this, a particular online
            feature may not work as intended.
          </p>
          <h6>COOKIES SET BY THIS WEBSITE</h6>
          <strong>Google Analytics</strong>
          <p>Cookies: _utma; _utmb; _utmc; _utmz</p>
          <p>
            Expiry: 2 years; 30 minutes; when you quit your browser; 6 months
            (respectively)
          </p>
          <p>
            Purpose: These cookies collect information about how our visitors
            use this site. This data is collected anonymously, and is used to
            help improve this website’s functionality. For more information, see
            Google Analytic’s page on safeguarding your data.
          </p>
          <strong>Expression Engine</strong>
          <p>
            Expression engine is the software that this website is built on. It
            sets the following cookies:
          </p>
          <p>exp_tracker</p>
          <p>Expiry: End of session.</p>
          <p>
            Purpose: Contains the last 5 pages viewed. Typically used for form
            or error message returns.
          </p>
          <p>exp_last_visit</p>
          <p>Expiry: 1 year.</p>
          <p>
            Purpose: Date of the user’s last visit. Can be shown as a statistic
            for members and could be used by a forum to show unread topics for
            both members and guests.
          </p>
          <p>exp_last_activity</p>
          <p>Expiry: 1 year.</p>
          <p>
            Purpose: Records the time of the last page load. Used in conjunction
            with the last_visit cookie.
          </p>

          <p>av</p>
          <p>Expiry: End of session.</p>
          <p>
            Purpose: Allows the site to display pages to users based on the date
            of birth entered into the age verification page.
          </p>

          <h6>TRIP ADVISOR</h6>
          <p>
            This website uses a third party widget by TripAdivsor. Use of this
            widget means you accept TripAdvisor’s Cookie Policy.
          </p>
          <p>
            This policy is separate from Distell’s and can be viewed on
            <a
              target="_blank"
              href="https://tripadvisor.mediaroom.com/UK-cookie-policy"
            >
              TripAdvisor’s website.
            </a>
          </p>
          <p>
            TripAdvisor reserves the right to modify the terms of this policy at
            any time, with or without notice.
          </p>
          <h6>MANAGING COOKIES</h6>
          <p>
            Most Internet browsers allow users to control the acceptance of
            cookies via the browser’s settings. Visit allaboutcookies.org to
            find out how to configure these settings on a number of major
            browsers.
          </p>
          <p>
            You can opt out of being tracked by Google Analytics across all
            websites by installing Google’s opt-out browser add-on.
          </p>
        </StaticPage>
      </React.Fragment>
    )
  }
}

export default CookiePolicy
