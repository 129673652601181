import React from "react"

class NotFound extends React.Component<{}> {
  public render() {
    return (
      <React.Fragment>
        <div className="flex h-screen" style={{ backgroundColor: "black" }}>
          <div className="flex flex-col w-full self-center">
            <div className="flex justify-center">
              <span
                style={{
                  fontFamily: "Times New Roman",
                  color: "#baab8f",
                  marginTop: 20,
                  fontSize: 35,
                }}
              >
                Not Found!
              </span>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default NotFound
