import React from "react"

import { CustomButtonStyles } from "../styles/Components"
export interface Props {
  className: string
  disabled: boolean
  large?: boolean
  onClick?: () => void
  text: string
}

class CustomButton extends React.Component<Props, {}> {
  public render() {
    const { className, text, onClick, large, disabled } = this.props
    return (
      <button
        className={className}
        onClick={onClick}
        style={
          large ? CustomButtonStyles.largeLarge : CustomButtonStyles.smallButton
        }
        disabled={disabled}
      >
        {text}
      </button>
    )
  }
}

export default CustomButton
