export const CustomButtonStyles = {
  smallButton: {
    width: "14rem",
    height: 60,
  },
  largeLarge: {
    width: "16rem",
    height: 35,
  },
  dropDown: {
    width: "100%",
    // overflowY:"scroll"
  },
}
export const InformationFormStyles = {
  text: {
    fontFamily: "New Gothic BT, Sans-serif",
    fontWeight: "bold",
    color: "#baab8f",
    margin: 20,
    fontSize: 40,
  },
  inputMarginLeft: { marginLeft: 2 },
  inputMarginRight: { marginRight: 2 },
  rowMarginTop: { marginTop: 6 },
  // selectOption:{height:80 , width:200}
}
