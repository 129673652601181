import React from "react"

import StaticPage from "@components/StaticPage"

const FAQ = () => {
  return (
    <StaticPage title="FREQUENTLY ASKED QUESTIONS">
      <h3>Bunnahabhain ‘Feis Ile Experience’ Promotion FAQs</h3>
      <ol>
        <li>
          <b>When does the promotion close?</b>
          <ul>
            <li>The Promotion closes at 11.59pm on the 30th June 2021.</li>
          </ul>
        </li>

        <li>
          <b>How do I enter?</b>
          <ul>
            <li>
              Simply enter the unique code, found inside your bottle tube on the
              promotional leaflet, at:{" "}
              <a href="https://www.discoverthesoundofislay.co.uk">
                www.discoverthesoundofislay.co.uk
              </a>{" "}
              for your chance to win.
            </li>
            <li>
              Once you enter the unique code on the microsite, you will receive
              an email confirming your entry.
            </li>
            <li>
              By entering the promotion, you will be entered into the prize
              draw. The winner will be announced in July 2021.{" "}
            </li>
            <li>
              You will also find out instantly if you have won one of our runner
              up prizes. You will be notified on screen immediately.
            </li>
            <li>
              You will be notified via email if you are a winner and how to
              claim your prize
            </li>
            <li>
              Please remember to retain the packaging containing your unique
              code as it may be needed for prize verification.
            </li>
          </ul>
        </li>

        <li>
          <b>What can I win?</b>
          <ul>
            <li>
              Our main prize is a VIP Islay festival experience. This includes a
              2 night stay on Islay and VIP tickets to Feis Ile and our
              distillery. There is one trip to be won for two people. Trip dates
              will be in 2022 (late May – festival dates are to be announced)
              and are to be organised once winners are announced to coincide
              with availability
            </li>
            <li>
              Our runner up prize is 1 of 201 adventure essentials. including 5
              x Garmin Venu smartwatches, 5 x SONY A6000 Compact cameras, 10 x
              Patagonia Blackhole 25L rucksacks, 181 branded Bunnahabhain water
              bottles.
            </li>
          </ul>
        </li>

        <li>
          <b>Will the current Coronavirus pandemic affect this promotion?</b>
          <ul>
            <li>
              We are monitoring the COVID-19 situation closely. We will keep all
              winners up to date via email if anything changes.
            </li>
          </ul>
        </li>

        <li>
          <b>Why can't I enter more than one code at the same time?</b>
          <ul>
            <li>
              Entries are restricted to one entry per person, per week, to
              comply with UK Licensing Laws on alcohol promotions.
            </li>
          </ul>
        </li>

        <li>
          <b>Why isn’t my code working?</b>
          <ul>
            <li>
              Please check that your code has been entered correctly: in capital
              letters and numbers, no spaces, if your code has characters that
              could be mistaken please check the alternative (e.g. 0 could be
              zero or the letter O for Oscar. I could be the number one or the
              letter I for India or a lower case l for lima)
            </li>
            <li>
              If you have tried to enter you code too many times, it may have
              timed you out of our site. Please instead try in a different
              internet browser or wait to re-try your code in a short while.
            </li>
          </ul>
        </li>

        <li>
          <b>How do I get in touch should I have a query?</b>
          <ul>
            <li>
              Email:{" "}
              <a href="mailto:support@discoverthesoundofislay.co.uk">
                support@discoverthesoundofislay.co.uk
              </a>
            </li>
          </ul>
        </li>
      </ol>
      <div className="separator"></div>
    </StaticPage>
  )
}

export default FAQ
