import React from "react"
import Modal from "react-modal"

import CustomButton from "@components/CustomButton"

const WinnerModal = ({ isOpen, closeModal, title, image }) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      borderRadius: 34,
      maxWidth: "80%",
    },
    overlay: {
      backgroundColor: "rgb(0,0,0,0.75)",
    },
  }

  return (
    <Modal isOpen={isOpen} style={customStyles} contentLabel="Example Modal">
      <div className="flex flex-col">
        <div className="flex justify-center">
          <span
            style={{
              fontFamily: "Times New Roman",
              color: "#baab8f",
              margin: "0.4rem",
              fontSize: 29,
              textAlign: "center",
              textTransform: "uppercase",
            }}
          >
            CONGRATULATIONS!!
          </span>
        </div>
        <div className="flex justify-center">
          <span
            style={{
              fontFamily: "Times New Roman",
              color: "#baab8f",
              margin: "0.4rem",
              fontSize: 23,
              textAlign: "center",
              textTransform: "uppercase",
              maxWidth: "24rem",
            }}
          >
            {title}
          </span>
        </div>

        <img
          className="mt-10"
          style={{ width: 150, alignSelf: "center" }}
          src={image}
        ></img>
        <div
          className="flex flex-col items-center"
          style={{ maxWidth: "28rem" }}
        >
          <CustomButton
            text={"Continue"}
            disabled={false}
            className={"button mt-3 mb-5"}
            onClick={closeModal}
          />
          <div style={{ color: "gray", fontSize: "12px" }}>
            The image shown is for illustration purposes only and may not be an
            exact representation of the prize item. The Promoter reserves the
            right to change the prize item images and specifications at any time
            without notice.
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default WinnerModal
