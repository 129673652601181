import { instance as axios } from "../../@api/axios";
import { CompetitionData, ActionTypeDate } from "../redux/ActionTypes";


export const addUserData = (obj) => {

    return (dispatch) => {
        dispatch({
            type: CompetitionData.SEND_USER_COMPETITION_DATA_START,
        });

        axios.post('/reward.php', obj)
            .then((res) => {
                addUserDataSuccess(dispatch, res.data);
            })
            .catch((error) => {
                addUserDataFail(dispatch, "There was an error connection2");
            });
    };
};
const addUserDataFail = (dispatch, errorMessage) => {
    dispatch({
        type: CompetitionData.SEND_USER_COMPETITION_DATA_FAIL,
        payload: {
            errorMessage,
        },
    });
};
const addUserDataSuccess = (dispatch, data) => {
    dispatch({
        type: CompetitionData.SEND_USER_COMPETITION_DATA_SUCCESS,
        payload: data,
    });
};


export const setUserDateOfBirthday = (obj) => {
    return (dispatch) => {
        dispatch({
            type: ActionTypeDate.SET_DATE,
            payload: obj
        });
    };
};

export const subscribeEmail = (obj) => {

    return (dispatch) => {
        dispatch({
            type: CompetitionData.SUBSCRIBE_NEWSLETTER_START,
        });

        axios.post('/subscribe.php', obj)
            .then((res) => {
                dispatch(subscribeEmailSuccess());
            })
            .catch((error) => {
                dispatch(subscribeEmailFail());
            });
    };
};

const subscribeEmailFail = () => ({
    type: CompetitionData.SUBSCRIBE_NEWSLETTER_FAIL,
});

const subscribeEmailSuccess = () => ({
    type: CompetitionData.SUBSCRIBE_NEWSLETTER_SUCCESS,
});
