import React, { Component } from "react"
import ReactGA from "react-ga"
import { Provider } from "react-redux"
import { Redirect, Route, Switch } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { applyMiddleware, compose, createStore } from "redux"
import ReduxThunk from "redux-thunk"

import HomePage from "@modules/home/HomePage"
import reducers from "@redux/redux/reducer"
import NotFound from "./@components/NotFound"
import CustomRoute from "./@layout/CustomRoute"
import CookiePolicy from "./@modules/cookiePolicy"
import FAQ from "./@modules/faq"
import PrivacyPolicy from "./@modules/privacyPolicy"
import TermsAndConditions from "./@modules/termsAndConditions"
import "./App.css"
class App extends Component {
  public render() {
    ReactGA.initialize("UA-156345665-2")
    const composeEnhancers =
      (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ as typeof compose) || compose

    const store = createStore(
      reducers,
      composeEnhancers(applyMiddleware(ReduxThunk)),
    )
    return (
      <Provider store={store}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          newestOnTop={false}
          hideProgressBar={true}
          closeOnClick
          rtl={false}
          draggable
          pauseOnHover
        />
        <Switch>
          <Route path="/home" component={HomePage} />
          <CustomRoute exact path="/faq" component={FAQ} />
          <CustomRoute exact path="/privacy-policy" component={PrivacyPolicy} />
          <CustomRoute exact path="/cookie-policy" component={CookiePolicy} />
          <CustomRoute
            exact
            path="/terms-conditions"
            component={TermsAndConditions}
          />
          <Route exact path="/not-found" component={NotFound} />
          <Route exact path="/" component={HomePage} />
          <Redirect to="/not-found" />
        </Switch>
      </Provider>
    )
  }
}

export default App
