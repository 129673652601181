import React from "react";
import Header from "../@modules/home/component/Header";
import AppFooter from "../@modules/home/component/AppFooter";

const Layout = (props) => {
    return (
        <>
            <Header />
            {props.children}
            <AppFooter />
        </>
    );
};
export default Layout;