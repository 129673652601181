import React, { useState } from "react"
import { useDispatch } from "react-redux"
// import ReactGA from "react-ga"
import CustomButton from "@components/CustomButton"
import DateField from "./DateField"
import MonthField from "./MonthField"
import YearField from "./YearField"
import { setUserDateOfBirthday } from "../../../@redux/competitionData/CompetitionAction"

const DateOfBirth = ({ toggleDateOfBirth }) => {
  const dispatch = useDispatch()
  const today = new Date()
  const [day, setDay] = useState(today.getDate())
  const [month, setMonth] = useState(today.getMonth() + 1)
  const [year, setYear] = useState(today.getUTCFullYear() - 18)
  const [errorClass, seterrorClass] = useState("")

  const calculateAge = (birthday) => {
    const ageDifMs = Date.now() - birthday
    const ageDate = new Date(ageDifMs)
    return Math.abs(ageDate.getUTCFullYear() - 1970)
  }
  const canBeSubmitted = () => {
    return true
  }
  const handleDayChange = (e) => {
    setDay(e.target.value)
  }

  const handleMonthChange = (e) => {
    setMonth(e.target.value)
  }

  const handleSubmit = () => {
    const date = `${year}-${month}-${day}`
    // ReactGA.event({
    //   category: "DOB FORM SUBMIT",
    //   action: `${day}-${month}-${year}`,
    //   label: "DATE OF BIRTH",
    // })
    dispatch(setUserDateOfBirthday(date))
    toggleDateOfBirth()
  }
  const handleYearChange = (e) => {
    setYear(e.target.value)
    const date = day + 1
    const dob = date + " " + month + " " + year
    if (calculateAge(new Date(dob)) < 18) {
      seterrorClass(
        "flex m-2 text-center justify-center font-new-roman text-red",
      )
    } else {
      seterrorClass(
        "flex m-2 text-center justify-center font-new-roman text-white",
      )
    }
  }
  return (
    <>
      <span className="text-center m-2 text-white text-xs">
        PLEASE ENTER YOUR DATE OF BIRTH
      </span>
      <div className="flex flex-row justify-center">
        <div>
          <DateField
            value={day}
            onChange={handleDayChange}
            error={errorClass.includes("text-red")}
          />
        </div>
        <div>
          <MonthField
            value={month}
            onChange={handleMonthChange}
            error={errorClass.includes("text-red")}
          />
        </div>
        <div>
          <YearField
            value={year}
            onChange={handleYearChange}
            error={errorClass.includes("text-red")}
          />
        </div>
      </div>
      <div className="flex justify-center">
        <CustomButton
          className={
            canBeSubmitted() ? "button mt-4 mb-6" : "disable_button mt-4 mb-6"
          }
          large
          text={"Submit"}
          onClick={handleSubmit}
          disabled={canBeSubmitted() ? false : true}
        ></CustomButton>
      </div>
      <div
        className={errorClass}
        style={{
          maxWidth: 600,
          display: "flex",
          alignSelf: "center",
          color: "white",
          margin: "20px 10px 25px 10px",
          textAlign: "justify",
          fontSize: "medium",
          fontFamily: "Palatino Linotype ,serif",
        }}
      >
        Please note, you must be aged 18 or over to take part in this promotion.
      </div>
    </>
  )
}

export default DateOfBirth
