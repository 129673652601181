import React, { Component } from "react"

import classes from "../styles/CustomInput.module.css"

interface OwnProps {
  autoFocus?: boolean
  error?: boolean
  errorMessage?: string
  large?: boolean
  maxLength?: number
  medium?: boolean
  name?: string
  onBlur?: () => void
  onChange: (e: any) => void
  placeholder?: string
  setFieldRef?: any
  value?: string
}

export default class CustomInput extends Component<OwnProps> {
  public render() {
    const {
      error,
      onChange,
      onBlur,
      value,
      autoFocus,
      placeholder,
      maxLength,
      setFieldRef,
      large,
      medium,
      name,
      errorMessage,
    } = this.props
    const inputClasses = [classes.blackInput]
    if (error) {
      inputClasses.push(classes.error)
    }

    if (medium) {
      inputClasses.push(classes.mediumField)
    }

    if (large) {
      inputClasses.push(classes.largeField)
    }

    return (
      <>
        <input
          className={inputClasses.join(" ")}
          autoFocus={autoFocus}
          autoComplete="off"
          type="text"
          name={name}
          placeholder={placeholder}
          value={value}
          maxLength={maxLength || 2}
          onChange={onChange}
          onBlur={onBlur}
          ref={setFieldRef}
        />
        {errorMessage && (
          <div className={classes.errorMessage}>{errorMessage}</div>
        )}
      </>
    )
  }
}
